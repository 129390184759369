import { useContext, useState } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json"
import 'react-pivottable/pivottable.css';
import {ItemsTableDefault} from "../../../../Various/ItemsTable/ItemsTable";

function ChatMessages({ messages, height = "350px", ...props }) {
    const [sortColumns, setSortColumns] = useState([])
    var [filterColumns, setFilterColumns] = useState({})
    const [itemActive, setItemActive] = useState(null)

    const clickHandler = (item, column, index, itemOrig) => {
        console.log(itemOrig)
        setItemActive(itemOrig)
    }
    return <>
        <div style={{ height }} className='content'>
            <ItemsTableDefault
                clickHandler={clickHandler}

                tableData={messages}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                noMemo={true}
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
                {...props}
            />
        </div>
        { itemActive ? <p ><b>{itemActive.role.charAt(0).toUpperCase() + itemActive.role.slice(1)}:</b> <br />{itemActive.message}</p> : <div>nothing selected</div> }
    </>
}

export default function EdufindChatsResult() {
    const { response } = useContext(QueryContext)
    const [itemActive, setItemActive] = useState(null)

    const clickHandler = (item, column, index, itemOrig) => {
        console.log(itemOrig)
        setItemActive(itemOrig)
    }

    return <>
    <Tabs>
        <TabList>
            {response &&
                <>
                    <Tab>Hits ({response.hits.hits.length})</Tab>
                </>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <>
                <TabPanel>
                    <ResultTabPanelItemsTable
                        height={"350px"}
                        clickHandler={clickHandler}
                        fn_dataTransform={(response) => response.hits.hits.map(x => {
                            return {
                                "chat_id": x.fields['payload.chat_id.keyword'][0],
                                timestamp: x.fields['timestamp'][0],
                                messages: x.inner_hits.messages.hits.hits.map(message => {
                                    return {
                                        "timestamp": message._source.timestamp,
                                        "message": message._source.payload.message,
                                        "role": message._source.payload.role,
                                    }
                                })
                            }
                        })
                        }
                    />
                </TabPanel>
            </>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
        { itemActive ? <ChatMessages messages={itemActive.messages} /> : <div>nothing selected</div> }
    </>

}
