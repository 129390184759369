import React, { useEffect } from 'react';
import QueryContext from '../Context';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactJson from 'react-json-view';


export default function ConfigWrapper({ children, defaultIndexName, defaultProject, defaultProjectPath, hasQuery }) {
    const { query, setIndexByIndexName, formData } = React.useContext(QueryContext)

    useEffect(() => {
        if (defaultIndexName) {
            setIndexByIndexName(defaultProject, defaultProjectPath, defaultIndexName)
        }
    }, [])

    return <div style={{ maxHeight: "calc(100vh - 225px)", overflowY: "auto" }}     >
        <Tabs>
            <TabList>
                <Tab>Edit</Tab>
                {hasQuery &&
                    <Tab>Query (JSON)</Tab>
                }
                <Tab>Form-Data (JSON)</Tab>
            </TabList>
            <TabPanel>
                {children}
            </TabPanel>
            {hasQuery &&

                <TabPanel>
                    <ReactJson
                        src={query}
                        theme="monokai"
                        indentWidth={1}
                        enableClipboard={true}
                        displayDataTypes={false}
                    />
                </TabPanel>
            }
            <TabPanel>
                <ReactJson
                    src={formData}
                    theme="monokai"
                    indentWidth={1}
                    enableClipboard={true}
                    displayDataTypes={false}
                />
            </TabPanel>
        </Tabs>
    </div>

}