import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import ClientProvider from '../ClientProvider';

export default function ClientSelectSimple({ project_id, client, setClient, projectPath }) {
    const wsProvider = React.useContext(ClientProvider);
    const [options, setOptions] = useState([])

    useEffect(() => {
        // Register Callback
        // Return is the cleanup function
        wsProvider.ws.waitForSocketAndSend({
            action: "getClientsConfig",
            options: {
                project_id: project_id,
                selectProjectPath: projectPath
            }
        }, (response) => {
            const options = response.map((x) => ({ "value": x.client, "label": x.client }))
            setOptions(options)
        })

    }, [projectPath])


    const onChange = (result) => {
        setClient(result["value"])
    }
    return (
        <div>
            <Select
                className="clientSelect reactSelect"
                value={{ value: client, label: client }}
                onChange={onChange}
                options={[{ "value": "", "label": "" }].concat(options)}
            />
        </div>
    )

}
