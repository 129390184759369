import React from 'react';
import ClientProvider from '../Various/ClientProvider';
import WorkspaceCommand from './Command/WorkspaceCommand';
import WorkspaceItems from './Items/WorkspaceItems';
import WorkspaceCrawlerSettings from './Crawler/WorkspaceCrawlerSettings';
import WorkspaceClientSettings from './Client/WorkspaceClientSettings';
import WorkspaceSystem from './System/WorkspaceSystem'
import WorkspaceBatchJobs from './BatchJobs/WorkspaceBatchJobs'
import WorkspacePerformance from './Performance/WorkspacePerformance'
import WorkspaceStatisticsDiff from './StatisticsDiff/WorkspaceStatisticsDiff'
import WorkspaceCampaigns from './Campaigns/WorkspaceCampaigns';
import WorkspaceTopicsList from './Topics/WorkspaceTopicsList';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ButtonsContainer from '../Various/ButtonsTop/_ButtonsContainer'
import WorkspaceCommandBottom from './Command/WorkspaceCommandBottom'
import DialogYesNo from '../Various/Dialog/DialogYesNo';
import WorkspaceTopicsCombs from './Topics/WorkspaceTopicsCombs';
import WorkspaceTopicsToDo from './Topics/WorkspaceTopicsToDo';
import WorkspaceESQuery from './ES/Workspace/WorkspaceESQuery';


export default function Workspace() {
    const wsProvider = React.useContext(ClientProvider);
    const [workspaceTabs, setWorkspaceTabs] = wsProvider.useStateVarGlobal("workspaceTabs", 0)
    const [catsWorkspaceTabs, setCatsWorkspaceTabs] = wsProvider.useStateVarGlobal("catsWorkspaceTabs", 0)
    return (
        <div className="col-sm-12 workspaceMain">
            <DialogYesNo>
                <Tabs
                    selectedIndex={workspaceTabs}
                    onSelect={setWorkspaceTabs}
                >
                    <TabList>
                        <div className="row">
                            <div className="col-7">
                                <Tab>Items (<ItemsCount wsProvider={wsProvider} />)</Tab>
                                <Tab>Command</Tab>
                                <Tab>Crawler</Tab>
                                <Tab>Client</Tab>
                                <Tab>Topics</Tab>
                                <Tab>System</Tab>
                                <Tab>Batch-Jobs</Tab>
                                <Tab>Performance</Tab>
                                <Tab>StatisticsDiff</Tab>
                                <Tab>Campaigns</Tab>
                                <Tab>ES</Tab>
                                {/*
                                    <Tab>Snapshots</Tab>
                                */}
                            </div>
                            <ButtonsContainer />
                        </div>
                    </TabList>
                    <TabPanel className="workspaceTabPanel">
                        {wsProvider.commandsTreeReady() &&
                            <WorkspaceItems />
                        }
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel">
                        {wsProvider.commandsTreeReady() &&
                            <div className="row">
                                <div className="col-12 workspaceTop">
                                    <div className="row">
                                        <WorkspaceCommand />
                                    </div>
                                </div>
                                <div className="col-12 workspaceBottom">
                                    {wsProvider.CommandsTree.getAttr("key") && wsProvider.CommandsTree.getAttr("className") &&
                                        <WorkspaceCommandBottom key={wsProvider.CommandsTree.getAttr("key") + wsProvider.CommandsTree.getAttr("className") + wsProvider.CommandsTree.getAttr("title")} />
                                    }
                                </div>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelCrawler">
                        {wsProvider.getCrawlerSelected() &&
                            <WorkspaceCrawlerSettings />
                        }
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelClient">
                        <WorkspaceClientSettings key={wsProvider.clientSelected} />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceWithSubTabs">
                        <Tabs
                            selectedIndex={catsWorkspaceTabs}
                            onSelect={setCatsWorkspaceTabs}
                        >

                            <TabList>
                                <Tab>List</Tab>
                                <Tab>Combs/Rules</Tab>
                                <Tab>ToDo</Tab>
                                {/* 
                                <Tab>Categorization ({wsProvider.clientSelected})</Tab>
                                <Tab>Topics Groups (Franco)</Tab>
                                <Tab>Topics (Cats) (Franco)</Tab>
                                <Tab>Categorization (Berufsberatung)</Tab>
                                */}
                            </TabList>

                            <TabPanel className="workspaceTabPanel">
                                <WorkspaceTopicsList key={wsProvider.clientSelected} />
                            </TabPanel>
                            <TabPanel className="workspaceTabPanel workspaceTabPanelTopicsCombs">
                                <WorkspaceTopicsCombs key={wsProvider.clientSelected} />
                            </TabPanel>
                            <TabPanel className="workspaceTabPanel workspaceTopicsToDo">
                                <WorkspaceTopicsToDo />
                            </TabPanel>
                            {/** 
                            <TabPanel className="workspaceTabPanelEcedFull">
                                <WorkspaceMatchEcedFull key={wsProvider.clientSelected} name_version="EcedFullV2" />
                            </TabPanel>

                            <TabPanel className="workspaceTabPanel">
                                <WorkspaceTopics />
                            </TabPanel>
                            <TabPanel className="workspaceTabPanel">
                                <WorkspaceMatchTopicsEcedFull key={wsProvider.clientSelected} name_version="EcedFullV2" />
                            </TabPanel>
                            <TabPanel className="workspaceTabPanelBerufsberatung">
                                <WorkspaceMatchBerufsberatungCorrection name_version="EcedFullV2" />
                            </TabPanel>
                             */}

                        </Tabs>
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelCrawler">
                        <WorkspaceSystem />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelBatchJobs">
                        <WorkspaceBatchJobs />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelPerformance">
                        <WorkspacePerformance />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelStatisticsDiff">
                        <WorkspaceStatisticsDiff />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceTabPanelCampaigns">
                        <WorkspaceCampaigns key={wsProvider.clientSelected} />
                    </TabPanel>
                    <TabPanel className="workspaceTabPanel workspaceWithSubTabs">
                        <Tabs>
                            <TabList>
                                <Tab>Query</Tab>
                            </TabList>

                            <TabPanel className="workspaceTabPanel">
                                <WorkspaceESQuery />
                            </TabPanel>
                        </Tabs>
                    </TabPanel>
                </Tabs>
            </DialogYesNo>
        </div>
    )
    return null
}


function ItemsCount({ wsProvider }) {
    wsProvider.setDocumentTitle()

    if (wsProvider.crawlerTreeReady()) {
        const items = wsProvider.CrawlerTree.getItems()
        return items !== null ? items.length : "0"
    }
    return "0"
}