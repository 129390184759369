import React, { useState } from 'react'
import ClientProvider from '../ClientProvider';
import { saveClient } from './ButtonSaveClient'

export default function ButtonRunCrawlerParts() {
  const wsProvider = React.useContext(ClientProvider);
  //var countReceivedItems = ""
  //var toPlaceIndexes={}
  const [sourceSelected, setSourceSelected] = wsProvider.useStateVar("crawler", "sourceSelected", {})
  const crawlerKey = wsProvider.getCrawlerSelected()
  const actionHandler = () => {
    saveClient(wsProvider, () => {

      console.log("Going to Run Crawler Parts")

      var sourceList = [sourceSelected]

      //Delete updated from actual Items-List
      wsProvider.CrawlerTree.setItems(items2 => {
        var itemsNew = []
        items2.forEach(item => {
          var allowed = true
          sourceList.forEach(sourceDict => {
            if (sourceDict["#"] == item["#source"]["#"] && sourceDict["crawler"] == item["#source"]["crawler"]) {
              allowed = false
            }
          })
          if (allowed === true) {
            itemsNew.push(item)
          }
        })
        return itemsNew
      }, crawlerKey)

      wsProvider.ws.waitForSocketAndSend({
        action: "runCrawlerSingleItem",
        options: {
          client: wsProvider.clientSelected,
          project_id: wsProvider.selectedProjectId,
          crawlerSelected: crawlerKey,
          sourceList: sourceList,
          crawlItemsMax: 100000,
          save_xlsx: false
        }
      }, (response) => {
        receiveItems(response, wsProvider.clientSelected, sourceList)
      })
    })
  }

  const receiveItems = (response, client, sourceList) => {
    //console.log("GOT IT")
    if (response["type"] === 'item') {
      var itemNew = response["content"]

      //Append new ones
      wsProvider.CrawlerTree.setItems(items2 => {
        return [
          ...items2,
          itemNew
        ]
      }, crawlerKey)
    } else if (response["type"] === 'stats') {
      const statsReceive = response["content"]
      wsProvider.updateStats(statsReceive, crawlerKey, client, { "type": "sourceList", "sourceList": sourceList })

      //Ready for NEXT-Buttons
      //wsProvider.setLoadFirstTableItem(true)

    }
  }

  return (
    <>
      <div className="col-1">
        <div className="d-grid gap-2">
          <button
            onClick={actionHandler}
            className="btn btn-primary btn-sm"
            type="button"
            disabled={wsProvider.clientConfig.length > 0 && wsProvider.connectedStatus && sourceSelected["#"] && !wsProvider.checkIsLoading() ? '' : 'disabled'}
          >
            {"Run Single" + (sourceSelected["#"] ? " (" + sourceSelected["#"] + ")" : "")}
          </button>
        </div>
      </div>
    </>
  )
}

