import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { useState } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import { DialogContext } from '../../Various/Dialog/DialogYesNo'

import Form from "@rjsf/core";

import ExportButton from "../../Various/ExportButton";


export const projectPaths = [
  'templates',
  'fresh',
  'selected',
  'prepared',
  'pending',
  "test",
  'manually',
  'live',
  'onHold',
  'dismissed',
  'later',
  'berufsschule',
  'diverses'
]


export default function WorkspaceClientSettings() {
  const wsProvider = React.useContext(ClientProvider);
  const [clientRename, setClientRename] = useState(wsProvider.clientSelected)
  const [insertName, setInsertName] = useState("example-ch")
  const [insertUrl, setInsertUrl] = useState("")
  const dialogContext = React.useContext(DialogContext);

  const schema = {
    type: 'object',
    properties: {
      projectPath: {
        enum: projectPaths,
        title: 'projectPath',
        type: 'string'
      },
      title: {
        type: 'string',
      },
      title_de: {
        type: 'string',
      },
      title_fr: {
        type: 'string',
      },
      title_it: {
        type: 'string',
      },
      title_en: {
        type: 'string',
      },
      title_short: {
        type: 'string',
      },
      title_short_de: {
        type: 'string',
      },
      title_short_fr: {
        type: 'string',
      },
      title_short_it: {
        type: 'string',
      },
      title_short_en: {
        type: 'string',
      },
      url_rewrite: {
        type: 'string'
      },
      url_rewrite_de: {
        type: 'string'
      },
      url_rewrite_fr: {
        type: 'string'
      },
      url_rewrite_it: {
        type: 'string'
      },
      url_rewrite_en: {
        type: 'string'
      },
      vcpuMemory: {
        enum: [
          "",
          "0.25|512",
          "0.25|1024",
          "0.25|2048",
          "0.5|1024",
          "0.5|2048",
          "0.5|3072",
          "0.5|4096",
          "1|2048",
          "1|3072",
          "1|4096",
          "1|5120",
          "1|6144",
          "1|7168",
          "1|8192",
          "2|12288",
          "2|16384",
          "4|24576",
          "4|30720",
          "8|61440",
        ],
        title: 'vcpuMemory (0.5|2048)',
        type: 'string',
        default: ""
      },
      batchTimeoutSec: {
        type: 'string',
        title: "batchTimeoutSec (18000)",
        default: "",
      },
      itemsTitleNormalizeDeactivate: {
        type: 'boolean',
        default: false,
      },
      /*use_pre_cache: {
        type: 'boolean',
        default: true,
      },*/
      autoload_items: {
        type: 'boolean',
        default: true,
      },
      autoload_stats: {
        type: 'boolean',
        default: true,
      },
      url: {
        type: 'string',
        title: "Website"
      },
      phone: {
        type: 'string',
        title: "Telefon"
      },
      email: {
        type: 'string',
        title: "E-Mail"
      },
      direction: {
        type: 'string',
        title: "Adresse"
      },
      kanton: {
        type: 'string',
        title: "Kanton"
      },
      geocode: {
        type: 'string',
        title: "Geocode"
      },
      notes: {
        type: 'string',
        title: "Bemerkungen"
      },
      source: {
        type: 'string'
      },
      source_url: {
        type: 'string'
      },
      source_id: {
        type: 'string'
      },
      logo_url: {
        type: 'string'
      },
      topic_group_regex_must: {
        type: 'string',
        title: 'topic_group_regex_must, e.g.: ^11###1110.*',
      },
      subpages_existing: {
        title: 'subpages_existing',
        type: 'boolean'
      },
      "favicon": {
        "type": "string",
        "format": "data-url",
        "title": "Favicon"
      }
    },
    dependencies: {},
    required: []
  }

  const uischema = {
    'ui:order': [
      'projectPath',
      'title',
      'title_short',
      'url_rewrite',
      'vcpuMemory',
      'batchTimeoutSec',
      'itemsTitleNormalizeDeactivate',
      // 'use_pre_cache',
      'autoload_items',
      'autoload_stats',
      'url',
      'phone',
      'email',
      'direction',
      'kanton',
      'geocode',
      'notes',
      'source',
      'source_url',
      'source_id',
      'logo_url',
      'favicon',
      'subpages_existing',
      'topic_group_regex_must',
      'title_de',
      'title_fr',
      'title_it',
      'title_en',
      'title_short_de',
      'title_short_fr',
      'title_short_it',
      'title_short_en',
      'url_rewrite_de',
      'url_rewrite_fr',
      'url_rewrite_it',
      'url_rewrite_en'

    ],
    direction: {
      'ui:widget': 'textarea'
    },
    notes: {
      'ui:widget': 'textarea'
    }
  }


  const changeHandler = (e) => wsProvider.setClientConfig([{
    ...wsProvider.clientConfig[0],
    "settings": e.formData
  }])


  const clientRenameHandler = () => {
    dialogContext.openDialog({
      title: "Rename: Saving of Client",
      prompt: "You have to save the Client before renaming it. Otherwise changes get lost. Do you wanna continue?",
      handleYes: () => {
        wsProvider.ws.waitForSocketAndSend({
          action: "clientRename",
          options: {
            project_id: wsProvider.selectedProjectId,
            clientFrom: wsProvider.clientSelected,
            clientTo: clientRename
          }

        }, (response) => {
          if (response === "ok") {
            wsProvider.refreshClients()
            wsProvider.changeClientHandler(clientRename)
          } else {
            alert(response)
          }
        })
      },
      handleNo: () => { }
    })
  }

  const clientDeleteHandler = () => {
    dialogContext.openDialog({
      title: "Delete Client " + wsProvider.clientSelected,
      prompt: "Are you sure you want to delete this Client? There is no way back.",
      handleYes: () => {
        wsProvider.ws.waitForSocketAndSend({
          action: "clientDelete",
          options: {
            project_id: wsProvider.selectedProjectId,
            client: wsProvider.clientSelected
          }
        }, (response) => {
          wsProvider.refreshClients()
        })
      },
      handleNo: () => { }
    })
  }

  const clientInsertHandler = () => {
    dialogContext.openDialog({
      title: "Insert Client " + insertName,
      prompt: "You have to save this Client before inserting a new one. Otherwise changes get lost. Do you wanna continue?",
      handleYes: () => {
        wsProvider.ws.waitForSocketAndSend({
          action: "clientInsert",
          options: {
            project_id: wsProvider.selectedProjectId,
            clientNew: insertName,
            url: insertUrl
          }
        }, (response) => {
          if (response === "ok") {
            wsProvider.refreshClients()
            wsProvider.changeClientHandler(insertName)
          } else {
            alert(response)
          }
        })
      },
      handleNo: () => { }
    })
  }

  if (wsProvider.clientConfig[0] === undefined) {
    return null
  }

  const [workspaceClientTabs, setWorkspaceClientTabs] = wsProvider.useStateVarGlobal("workspaceClientTabs", 0)

  return (
    <>
      <Tabs
        selectedIndex={workspaceClientTabs}
        onSelect={setWorkspaceClientTabs}
      >
        <TabList>
          <Tab>Settings</Tab>
          <Tab>Admin</Tab>
        </TabList>
        <TabPanel className="">
          <Form
            schema={schema}
            uiSchema={uischema}
            formData={wsProvider.clientConfig[0]["settings"]}
            onChange={changeHandler}
            className={"crawlerSettings"}
          //widgets={widgets}
          />
        </TabPanel>
        <TabPanel className="">
          <div className='row'>
            <div className='col-1'>
              <div className="d-grid gap-2">
                <ExportButton
                  type={"client_config"}
                  label="Export Config"
                  parameters={{ "client": wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0] }}
                />
                <ExportButton
                  type={"client_items"}
                  label="Export Items"
                  parameters={{ "client": wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0] }}
                />
                <ExportButton
                  type={"client_stats"}
                  label="Export Stats"
                  parameters={{ "client": wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0] }}
                />
                <ExportButton
                  type={"client_cache"}
                  label="Export Cache"
                  parameters={{ "client": wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0] }}
                />
              </div>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-1'>
              <div className="d-grid gap-2">

                <input
                  name="clientRename"
                  value={clientRename}
                  className="form-control form-control-sm suffix"
                  onChange={(e) => setClientRename(e.target.value)}
                />
                <button
                  onClick={clientRenameHandler}
                  className="btn btn-primary btn-sm"

                >
                  <i className="bi bi-arrow-down-circle"></i> Rename Client
                </button>
                <button
                  onClick={clientDeleteHandler}
                  className="btn btn-primary btn-sm"
                >
                  <i className="bi bi-trash"></i> Delete Client
                </button>
              </div>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-1'>
              <div className="d-grid gap-2">

                <input
                  name="insertName"
                  value={insertName}
                  className="form-control form-control-sm suffix"
                  onChange={(e) => setInsertName(e.target.value)}
                />
                <input
                  name="insertUrl"
                  value={insertUrl}
                  placeholder={"URL..."}
                  className="form-control form-control-sm suffix"
                  onChange={(e) => setInsertUrl(e.target.value)}
                />
                <button
                  onClick={clientInsertHandler}
                  className="btn btn-primary btn-sm"
                >
                  <i className="bi bi-plus"></i> Insert
                </button>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </>
  )
}