import Select from 'react-select';
import React from 'react';
import ProjectProvider from '../ProjectProvider';
import ProjectPathProvider from "../ProjectPathProvider";

export default function ProjectPathSelect({ selectedProjectPath, setSelectedProjectPath }) {
    
    const projectProvider = React.useContext(ProjectProvider);
    const projectPathProvider = React.useContext(ProjectPathProvider);

    const onChange = (result) => {
        setSelectedProjectPath(result["value"])
    }

    const options = (projectPathProvider.selectedProjectId && projectProvider.projects.length > 0) ?
        projectProvider.projects.filter(x => x['id'] === projectPathProvider.selectedProjectId)[0]['paths'].map(x => ({
            "value": x['id'],
            "label": x['name']
        })) : []

    return (
        <div>
            <Select
                className="projectPathSelect reactSelect"
                value={{ value: selectedProjectPath, label: options.filter(x => x['value'] === selectedProjectPath).map(x => x['label'])[0] }}
                onChange={onChange}
                options={[{ "value": "", "label": "" }].concat(options)}
            />
        </div>
    )
}
