import ProjectSelect from "./Select/ProjectSelect";
import React, { useEffect, useState } from "react";
import Select, { default as ReactSelect } from "react-select";
import ClientProvider from "./ClientProvider";
import ProjectProvider from "./ProjectProvider";
import ProjectPathSelect from "./Select/ProjectPathSelect";

export default function ProjectPathSelectorWidget(props) {
    const clientProvider = React.useContext(ClientProvider);

    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedProjectPath, setSelectedProjectPath] = useState(null);
    const [selectionType, setSelectionType] = useState(null);
    const [maxClients, setMaxClients] = useState(0);

    const selectionTypes = [
        { "value": "", "label": "" },
        { "value": "all_by_project_path", "label": "All By ProjectPath" },
        { "value": "single", "label": "Single Selection" },
        { "value": "multiple", "label": "Multiple Selection" },
        { "value": "text", "label": "Text" },
    ]

    const onChange = (value) => {
        console.log(value)
        props.onChange(
            value
        )
    }
    useEffect(() => {
        console.log("props", props.value)

        if (props.value.hasOwnProperty("project")) {
            setSelectedProjectId(props.value["project"])
        }
        if (props.value.hasOwnProperty("projectPath")) {
            setSelectedProjectPath(props.value["projectPath"])
        }
        if (props.value.hasOwnProperty("selectionType")) {
            setSelectionType(props.value["selectionType"])
        }
        if (props.value.hasOwnProperty("maxClients")) {
            setMaxClients(props.value["maxClients"])
        }
    }, []);

    return (
        <>
            <div className="input-group mb-3">
                <ProjectSelect selectedProjectId={selectedProjectId} setSelectedProjectId={setSelectedProjectId} />
            </div>

            {selectedProjectId && (
                <div className="input-group mb-3">
                    <Select
                        value={selectionTypes.find((x) => (x.value === selectionType))}
                        className="projectPathSelect reactSelect"
                        onChange={(result) => setSelectionType(result["value"])}
                        options={selectionTypes}
                    />
                </div>
            )}

            {selectionType === "single" && (
                <div className="input-group mb-3">
                    <Select
                        value={clientProvider.clients.filter(
                            x => x['project'] === selectedProjectId && x['client'] === props.value?.selectionValue
                        ).map(x => ({ "value": x['client'], "label": x['client'] + ' (' + x['projectPath'] + ')' }))[0]}

                        className="projectPathSelect reactSelect"
                        onChange={(result) => {
                            onChange(
                                {
                                    "project": selectedProjectId,
                                    "projectPath": selectedProjectPath,
                                    "selectionType": selectionType,
                                    "selectionValue": result["value"],
                                    "maxClients": maxClients
                                })
                        }}
                        options={
                            [
                                { "value": "", "label": "" },
                            ].concat(
                                clientProvider.clients.filter(
                                    x => x['project'] === selectedProjectId
                                ).map(x => ({ "value": x['client'], "label": x['client'] + ' (' + x['projectPath'] + ')' }))
                            )}
                    />
                </div>
            )}
            {selectionType === "text" && (
                <div className="input-group mb-3">
                    <textarea
                        value={props.value.selectionValue}
                        className="form-control"
                        onChange={(result) => {
                            onChange(
                                {
                                    "project": selectedProjectId,
                                    "projectPath": selectedProjectPath,
                                    "selectionType": selectionType,
                                    "selectionValue": result.target.value,
                                    "maxClients": maxClients
                                })
                        }}
                    />
                </div>
            )}
            {selectionType === "all_by_project_path" && (
                <>
                    <div className="input-group mb-3">
                        <ProjectPathSelect
                            selectedProjectPath={selectedProjectPath}
                            setSelectedProjectPath={(path) => {
                                setSelectedProjectPath(path)
                                onChange(
                                    {
                                        "project": selectedProjectId,
                                        "projectPath": selectedProjectPath,
                                        "selectionType": selectionType,
                                        "selectionValue": path,
                                        "maxClients": maxClients
                                    })
                            }
                            } />
                    </div>
                    <div>
                        <label>Max clients</label>
                        <input
                            type="number"
                            className="form-control"
                            onChange={(result) => {
                                setMaxClients(result.target.value)
                                onChange(
                                    {
                                        "project": selectedProjectId,
                                        "projectPath": selectedProjectPath,
                                        "selectionType": selectionType,
                                        "selectionValue": selectedProjectPath,
                                        "maxClients": result.target.value
                                    })
                            }}
                            value={maxClients}
                        />
                    </div>
                </>
            )}
            {selectionType === "multiple" && (
                <ReactSelect
                    /* menuIsOpen={true} */
                    value={clientProvider.clients.filter(
                        x => props.value.selectionValue.includes(x['client'])
                    ).map(x => ({ "value": x['client'], "label": x['client'] + ' (' + x['projectPath'] + ')' }))}

                    className="reactSelect"
                    options={clientProvider.clients.filter(
                        x => x['project'] === selectedProjectId
                    ).map(x => ({ "value": x['client'], "label": x['client'] + ' (' + x['projectPath'] + ')' }))}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(result) => {
                        onChange({
                            "project": selectedProjectId,
                            "projectPath": selectedProjectPath,
                            "selectionType": selectionType,
                            "selectionValue": result.map(x => x["value"]),
                            "maxClients": maxClients
                        })
                    }}
                    allowSelectAll={true}
                />
            )}
        </>
    )
}