/* eslint-disable no-console, react/no-access-state-in-setstate */
import React from 'react';
import Tree from 'rc-tree';
import ClientProvider from '../../Various/ClientProvider';
import CommandPicker from '../../Various/CommandPicker'
import CompWrapper from '../CompWrapper'
import CommandSettings from './CommandSettings'

export function makeid() {
  let length = 20
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class CommandsTree extends React.Component {
  static contextType = ClientProvider

  constructor(props, context) {
    super(props, context)
    context.setCommandsTree(this)
    this.stats_loading = {}
  }

  onSelect = (selectedKeys, e) => {
    let commandSelected = this.getCommandSelectedFromNode(e.node)
    this.context.setCommandSelected(commandSelected)
    let lowestCommand = commandSelected[commandSelected.length - 1]
    switch (lowestCommand["className"]) {
      case "textProcessor":
        if (["location", "studymode", "eventtype", "degree", "cat0", "cluster", "level"].includes(lowestCommand["title"])) {
          this.context.setStateVar("command", "wordsLib_understand_cat", capitalize(lowestCommand["title"]))
        }
        break
    }
  };

  getCommandSelectedFromNode = (node) => {
    var results = []
    var ancArray = node.pos.split("-").map(x => parseInt(x))
    ancArray.shift()
    var moment = ancArray.shift()
    var ref = this.context.commandsConfig[moment]

    results.push(ref)
    ancArray.forEach(x => {
      ref = ref["children"][x]
      results.push(ref)
    })
    return results
  }

  allowDrop = ({ dropNode, dropPosition }) => {

    //const self = this.getAttr("className")

    //const parent = dropNode.className ? dropNode.className : 'loop'

    if (dropPosition === 0) {
      //if(types[parent].possibleChilds.includes(self))
      return true;
    } else if (dropPosition === 1) {
      //if(types[parent].possibleSiblings.includes(self))
      return true;
      //const withAncestors = this.getCommandSelectedFromNode(dropNode)
      //console.log(withAncestors)
      //var parent = withAncestors[withAncestors.length-2].className ? withAncestors[withAncestors.length-2].className : 'loop'
    }
  }

  onDragStart = info => {
    console.log('start', info);
  };

  onCheck = info => {
    console.log('check', info);
  };

  onDragEnter = () => {
    console.log('enter');
  };

  onDrop = info => {
    console.log('drop', info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...this.context.commandsConfig];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (dropPosition === 0) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || [];
        // where to insert 
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap (insert before or insert after)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.context.updateCommandsConfig(data)
    //console.log(info.node.pos + "-" + info.dropPosition)
    //Otherwise Buggy because path changes
    this.context.setCommandSelected([])
  };

  onExpand = expandedKeys => {
    this.context.setStateVar("crawler", "commandsExpanded", expandedKeys)
  };

  addCommands = (newNode, selectNode, toChild = false) => {
    var root = [...this.context.commandsConfig]
    var selectedObj = this.getSelectedFromData(root)
    if (selectedObj) {
      if (toChild) {
        let node = selectedObj.node
        node["children"] = [
          ...(node["children"] ? node["children"] : []),
          newNode
        ]
      } else {
        selectedObj.container.splice(selectedObj.key + 1, 0, newNode)
      }
    }
    else {
      console.log("push to root")
      root.push(newNode)
    }

    //Update Config
    this.context.updateCommandsConfig(root)

    //Update Selected
    if (selectNode) {
      var newSelected = [...this.context.getCommandSelected()]
      if (!toChild)
        newSelected.pop()
      newSelected.push(newNode)
      this.context.setCommandSelected(newSelected)
    }
  }

  toClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(this.getSelected()))
  }

  fromClipboard = () => {
    navigator.clipboard.readText()
      .then(text => {
        let obj = this.replaceKeysRecursiv(JSON.parse(text))
        this.addCommands(obj, true)
      })
      .catch(err => {
        console.log('No Access Granted', err);
      });

  }

  findCommandRecursiveByKey = (commandConfig, key) => {
    let found = commandConfig.find(x => x.key === key)
    if (found) {
      return found
    } else {
      commandConfig.forEach((command) => {
        if ("children" in command) {
          let found1 = this.findCommandRecursiveByKey(command["children"], key)
          if (found1) {
            found = found1
            return
          }
        }
      })
      return found
    }
  }

  addCommand = () => {
    this.addCommands({
      title: 'url',
      key: makeid(),
      className: "extract",
      settings: {}
    }, true)
  }

  deleteCommands = () => {
    var root = [...this.context.commandsConfig]
    var selectedObj = this.getSelectedFromData(root)

    selectedObj.container.splice(selectedObj.key, 1);

    this.context.updateCommandsConfig(root)

    //Update Selected
    var newSelected = [...this.context.getCommandSelected()]
    newSelected.pop()

    if (selectedObj.container.length === 0) {
      //Nothing left
      this.context.setCommandSelected([])
      return
    } else if (selectedObj.key < selectedObj.container.length) {
      //Any item
      newSelected.push(selectedObj.container[selectedObj.key])
    } else if (selectedObj.container.length === selectedObj.key) {
      //last Item
      newSelected.push(selectedObj.container[selectedObj.key - 1])
    }

    this.context.setCommandSelected(newSelected)
  }

  dupliCommands = () => {
    let obj = this.replaceKeysRecursiv(this.getSelected())
    this.addCommands(obj, true)
  }

  replaceKeysRecursiv = (obj) => {
    let objNew = {
      ...obj,
      key: makeid()
    }

    if ("children" in objNew)
      objNew["children"] = objNew["children"].map(x => { return { ...this.replaceKeysRecursiv(x) } });

    return objNew
  }


  getSelected = () => {
    if (this.context.getCommandSelected())
      return this.context.getCommandSelected()[this.context.getCommandSelected().length - 1]
  }

  getSelectedFromData = (root) => {
    var node = { children: root }
    var container
    var key
    this.context.getCommandSelected().forEach(x => {
      container = node.children
      key = node.children.findIndex(y => y.key === x.key)
      node = node.children.find(y => y.key === x.key)
    })
    if (key === undefined) //-1 would be correct
      return
    return {
      container: container,
      key: key,
      node: node
    }
  }

  getAttr = (key) => {
    const selected = this.getSelected()
    if (selected && key in selected)
      return this.getSelected()[key]
    else
      return
  }

  getAttrSettings = () => this.getAttr("settings")

  changeAttr = (key, value) => {
    var root = [...this.context.commandsConfig]
    var node = this.getSelectedFromData(root).node
    node[key] = value
    this.context.updateCommandsConfig(root)
  }

  //For input (not need because it's a dropdown now)
  //changeAttrTitle = (e) => this.changeAttr("title", e.target.value)

  changeAttrTitle = (e) => this.changeAttr("title", e.name)
  changeAttrType = (e) => this.changeAttr("className", e.name)
  changeAttrActive = (e) => {
    this.changeAttr("active", e.name === "false" ? false : true)
    var style = this.getAttr("style")
    if (style === undefined) {
      style = {}
    }
    var styleNew = {
      ...style,
      opacity: e.name === "false" ? "0.2" : "1"
    }
    this.changeAttr("style", styleNew)
  }
  changeAttrSettings = (e) => this.changeAttr("settings", e)

  setSingleSetting = (key1, newData) => {
    let temp = {
      ...this.getAttrSettings(),
    }
    temp[key1] = newData
    this.changeAttrSettings(temp)
  }
  getSingleSetting = (key, defaultValue) => {
    let settings = this.getAttrSettings()
    if (settings && key in settings)
      return settings[key]
    return defaultValue
  }

  findCommandRec = (f, container) => {
    if (container === undefined) {
      container = this.context.commandsConfig
    }

    var res = null

    for (let x of container) {
      if (f(x) === true) {
        res = x
        break
      } else if ("children" in x && x["children"].length) {
        res = this.findCommandRec(f, x["children"])
        if (res) {
          break
        }
      }
    }

    if (res) {
      return res
    } else {
      return false
    }
  }

  findCommandKeysParents = (key, currentNode) => {

    if (!currentNode)
      var currentNode = this.context.commandsConfig

    var result = false
    currentNode.forEach(commandDict => {
      if (commandDict["key"] === key) {
        result = [commandDict]
        return false
      } else if (commandDict["children"]) {
        var childResult = this.findCommandKeysParents(key, commandDict["children"])
        if (childResult) {
          childResult.unshift(commandDict)
          result = childResult
          return false
        } else {
          return false
        }
      }
    });
    return result
  }

  goToCommand = (key) => {
    const newSelected = this.findCommandKeysParents(key).map(x => x["key"])
    const [commandsExpanded, setCommandsExpanded] = this.context.useStateVar("crawler", "commandsExpanded", [])
    this.context.setStateVar("crawler", "commandSelected", newSelected)
    setCommandsExpanded(x => {
      if (x)
        return arrayUnique([...x, ...newSelected])
      else
        return newSelected
    })
  }

  getCommandsStats = () => {
    if (this.context.clientSelected in this.context.stats && this.context.getCrawlerSelected() in this.context.stats[this.context.clientSelected]) {
      return this.context.stats[this.context.clientSelected][this.context.getCrawlerSelected()]["commandsStats"]
    }
  }

  getStatsTime = () => {
    if (this.context.clientSelected in this.context.stats && this.context.getCrawlerSelected() in this.context.stats[this.context.clientSelected])
      return this.context.stats[this.context.clientSelected][this.context.getCrawlerSelected()]["time"]
  }

  loadStatsCommand = (commandKey) => {
    if (!commandKey) {
      return null
    }

    if (this.stats_loading[commandKey] === true) {
      return null
    }

    this.stats_loading[commandKey] = true

    this.context.ws.waitForSocketAndSend({
      action: "getStats",
      options: {
        client: this.context.clientSelected,
        project_id: this.context.selectedProjectId,
        crawler: this.context.getCrawlerSelected(),
        commandKeys: [commandKey]
      }
    }, (response) => {
      console.log("GOT NEW STATS for: " + commandKey)
      this.stats_loading[commandKey] = false
      this.context.updateStats(response, this.context.getCrawlerSelected(), this.context.clientSelected, { type: "command", command: commandKey })
    })
  }

  loadStatsAll = () => {
    this.context.ws.waitForSocketAndSend({
      action: "getStats",
      options: {
        client: this.context.clientSelected,
        project_id: this.context.selectedProjectId,
        crawler: this.context.getCrawlerSelected()
      }
    }, (response) => {
      console.log("GOT NEW STATS (loadStatsAll)")
      this.context.updateStats(response, this.context.getCrawlerSelected(), this.context.clientSelected, { type: "crawler" })
    })
  }

  getCommandStats = (loadStats = false, forceLoadStats = false) => {
    let command = this.getSelected()
    if (!command || !("key" in command))
      return null

    const commandKey = command["key"]
    if (!commandKey)
      return null

    const commandsStats = this.getCommandsStats()

    if (commandsStats === undefined || !(commandKey in commandsStats)) {
      if (loadStats === true && (this.context.clientConfig[0]["settings"]["autoload_stats"] !== false || forceLoadStats === true)) {
        this.loadStatsCommand(commandKey)
      }

      return null
    }
    var data2 = commandsStats[command["key"]]

    if (!data2)
      return null

    return data2
  }



  getProcConfigStats = () => {
    //SOME MEMO SHOULD HAPPEN HERE. BUT DATA IS USUALLY QUITE SMALL (also hole components could be memorized too in a parfect world...)
    const commandStats = this.getCommandStats()

    if (!commandStats)
      return null

    var result = {}

    Object.keys(commandStats["execs"]).forEach(itemId => {
      const execItem = commandStats["execs"][itemId]
      if ("procConfigStats" in execItem) {
        const procConfigStats = execItem["procConfigStats"]
        Object.keys(procConfigStats).forEach((key1) => {
          Object.keys(procConfigStats[key1]).forEach((key2) => {
            if (!(key1 in result))
              result[key1] = {}
            if (!(key2 in result[key1]))
              result[key1][key2] = {}
            result[key1][key2][execItem["#exec"]] = {
              "#source": execItem["#source"],
              "result": procConfigStats[key1][key2]
            }
          })
        })
      }
    })
    //console.log(result)
    return result
  }
  getShowAllScope = () => ["textProcessor", "valueToField"].includes(this.getAttr("className")) ? "crawler" : "command"
  getShowAllDefault = () => ["textProcessor", "filter", "if", "pipe", "pipeAll", "extractTable", "extractTableMulti"].includes(this.getAttr("className")) ? true : false

  render() {
    return (
      <CompWrapper
        className="CommandsTree"
        title="Commands"
        buttonBar={(
          <>
            <CommandPicker />
            <button
              onClick={this.addCommand}
              className="btn btn-primary btn-sm"
              disabled={this.context.getCrawlerSelected() ? "" : "disabled"}
            >
              <i className="bi bi-plus"></i>
            </button>
            <button
              onClick={this.fromClipboard}
              className="btn btn-primary btn-sm"
              disabled={this.context.getCrawlerSelected() ? "" : "disabled"}
            >
              <i className="bi bi-clipboard-plus"></i>
            </button>
            <button
              onClick={this.dupliCommands}
              className="btn btn-primary btn-sm"
              disabled={(this.getAttr("key")) ? "" : "disabled"}
            >
              <i className="bi bi-arrow-repeat"></i>
            </button>
            <button
              onClick={this.deleteCommands}
              className="btn btn-primary btn-sm"
              disabled={(this.getAttr("key")) ? "" : "disabled"}
            >
              <i className="bi bi-dash"></i>
            </button>
            <button
              onClick={this.toClipboard}
              className="btn btn-primary btn-sm"
              disabled={(this.getAttr("key")) ? "" : "disabled"}
            >
              <i className="bi bi-clipboard"></i>
            </button>
          </>
        )}
      >
        <Tree
          allowDrop={this.allowDrop}
          expandedKeys={this.context.getStateVar("crawler", "commandsExpanded", [])}
          selectedKeys={[this.getAttr("key")]}
          onExpand={this.onExpand}
          onSelect={this.onSelect}
          autoExpandParent={false}
          defaultExpandAll={true}
          draggable
          onDragStart={this.onDragStart}
          onDragEnter={this.onDragEnter}
          onDrop={this.onDrop}
          onCheck={this.onCheck}
          treeData={this.context.commandsConfig}
        />
        <CommandSettings />

      </CompWrapper>
    );
  }
}

export default CommandsTree;

function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
}