import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import ConfigForm from "../../Base/Config/ConfigForm";
import IndexSelector from "../../Base/Config/IndexSelector";
import RunButton from "./RunButton";
import RunButtonQuery from "../../Base/Config/RunButtonQuery";
import React, { useContext, useEffect } from "react";
import QueryContext from "../../Base/Context";
import ClientProvider from "../../../../Various/ClientProvider";

export default function EdufindChatsConfig() {
    const { formData, setIndexByIndexName } = useContext(QueryContext)
    const wsProvider = React.useContext(ClientProvider);

    const generate_query = () => {
        console.log("formData", formData)
        if (formData.query)
            return JSON.parse(formData.query)

    }

    useEffect(() => {
        setIndexByIndexName("edufind", wsProvider.project_path, "tracking")
    }, []);

    return <ConfigWrapper
    // defaultIndexName="tracking"  # multiple indices needed
    >
        <IndexSelector />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "query": {
                        "type": "string",
                    }
                }
            }}
            uiSchema={{
                "query": {
                    "ui:widget": "monacojson",
                    "height": "calc(100vh - 390px)"
                }
            }}
            defaultFormData={() => ({
                query: JSON.stringify({
                    "fields": ["timestamp"],
                    "query": {
                        "bool": {
                            "filter": {
                                "term": { "action_type": "chat_message" }
                            }
                        }
                    },
                    "collapse": {
                        "field": "payload.chat_id.keyword",
                        "inner_hits": {
                            "name": "messages",
                            "size": 1000,
                            "sort": [{ "timestamp": "asc" }]
                        },
                    },
                    "size": 100
                }, null, 2)
            })}
            className="rjsf_QueryRawConfig"
            generate_query={generate_query}
        />
        <RunButtonQuery />
    </ConfigWrapper>
}