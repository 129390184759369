import React, { useState } from 'react'
import ClientProvider from '../ClientProvider';

export function saveClient(wsProvider, callback = () => { }) {
  console.log("Going to Save Client Config")
  wsProvider.ws.waitForSocketAndSend({
    action: "saveClientConfig",
    options: {
      project_id: wsProvider.selectedProjectId,
      client: wsProvider.clientSelected,
      clientConfig: wsProvider.clientConfig
    }
  }, () => {
    callback()
  })
}

export default function ButtonSaveClient() {
  const wsProvider = React.useContext(ClientProvider);

  const actionHandler = () => {
    saveClient(wsProvider)
  }

  return (
    <div className="col-1">
      <div className="d-grid gap-2">
        <button
          onClick={actionHandler}
          className="btn btn-primary btn-sm"
          type="button"
          disabled={wsProvider.clientConfig.length > 0 && wsProvider.connectedStatus && !wsProvider.checkIsLoading() ? '' : 'disabled'}
        >
          Save
        </button>
      </div>
    </div>
  )
}